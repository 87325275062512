import { map } from "rxjs/operators";
import { combineEpics, ofType } from "redux-observable";
import * as actions from "./actions";

const startStoreEpic = action$ =>
  action$.pipe(
    ofType(actions.startStore().type),
    map(d => actions.storeStarted({ start: true }))
  );

const rootEpic = combineEpics(startStoreEpic);

export default rootEpic;
