import React from "react";
import { create } from "jss";
import rtl from "jss-rtl";
import JssProvider from "react-jss/lib/JssProvider";
import {
  MuiThemeProvider,
  createMuiTheme,
  createGenerateClassName,
  jssPreset
} from "../core/styles";

import { CssBaseline } from "../core";
import { red, indigo } from "../core/colors";

// A theme with custom primary and secondary color.
// It's optional.
const theme = (direction = "ltr", type = "light") =>
  createMuiTheme({
    direction,
    palette: {
      type,
      primary: indigo,
      secondary: red,
      error: red
    },
    typography: {
      useNextVariants: true
    },
    shape: {
      borderRadius: 4
    }
  });

// Create a JSS instance with the default preset of plugins.
// It's optional.
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

// The standard class name generator.
// It's optional.
const generateClassName = createGenerateClassName();

function withRoot(Component) {
  function WithRoot(props) {
    // JssProvider allows customizing the JSS styling solution.
    return (
      <JssProvider jss={jss} generateClassName={generateClassName}>
        {/* MuiThemeProvider makes the theme available down the React tree
          thanks to React context. */}
        <MuiThemeProvider theme={theme(props.direction, props.type)}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Component {...props} />
        </MuiThemeProvider>
      </JssProvider>
    );
  }

  return WithRoot;
}

export default withRoot;

/**todo document.body.dir = this.state.direction bunu bi kontrol et */
