import { handleActions } from "redux-actions";
import * as actions from "./actions";

const portfolioReducer = handleActions(
  {
    [actions.storeStarted]: (state, { payload }) => payload
  },
  {
    start: false
  }
);

export default portfolioReducer;
