import { combineReducers, compose, createStore, applyMiddleware } from "redux";
import { createEpicMiddleware, combineEpics } from "redux-observable";

// epics
import portfolioEpic from "./epics";

// reducers
import portfolioReducer from "./reducers";

const epicMiddleware = createEpicMiddleware();

// combine all epics
const rootEpic = combineEpics(portfolioEpic);

//combine all reducers
const reducer = combineReducers({
  store: portfolioReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore() {
  const store = createStore(
    reducer,
    composeEnhancers(applyMiddleware(epicMiddleware))
  );

  epicMiddleware.run(rootEpic);

  return store;
}
