import React from "react";
import { PosedRouter } from "wire-router";
import { connect } from "react-redux";
import { startStore } from "../store/actions";

import Home from "./Home";
import Template from "template";

const App = props => {
  props.startStore();

  return (
    <div>
      <PosedRouter>
        <Home path="/" />
        <Template path="/template" />
      </PosedRouter>
    </div>
  );
};

export default connect(
  null,
  { startStore }
)(App);
