import React from "react";
import PropTypes from "prop-types";

import { Typography } from "wireui/core";

import { withStyles } from "wireui/core";

import withRoot from "wireui/themes";

const styles = theme => ({
  root: {
    textAlign: "center",
    paddingTop: theme.spacing.unit * 20
  }
});

class Index extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h1" gutterBottom>
          Tasarım Daha Gelmedi!!!
        </Typography>
      </div>
    );
  }
}

Index.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRoot(withStyles(styles)(Index));
