import React from "react";
import { Router, Location } from "@reach/router";
import posed, { PoseGroup } from "react-pose";
//import { tween } from "popmotion";

export * from "@reach/router";

const RouteContainer = posed.div({
  enter: {
    opacity: 1,
    delay: 300,
    beforeChildren: true
  },
  exit: { opacity: 0 }
});

export const PosedRouter = ({ children }) => (
  <Location>
    {({ location }) => (
      <PoseGroup>
        <RouteContainer key={location.key} className="bg">
          <Router location={location}>{children}</Router>
        </RouteContainer>
      </PoseGroup>
    )}
  </Location>
);
